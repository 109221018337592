<template>
  <div class="profile-container">
    <div class="profile-container-header">
      <span>系统信息</span>
    </div>
    <div class="profile-container-content">
      <div class="profile-block">
        <span>登录账号：</span>
        <span>{{ username }}</span>
      </div>
      <div class="profile-block">
        <span>手机号码：</span>
        <span v-if="phone">{{ phone }}</span>
        <span v-else class="edit" @click="bindPhone">立即绑定</span>
        <span class="edit" @click="bindPhone" v-if="phone">修改</span>
      </div>
      <div class="profile-block">
        <span>单位名称：</span>
        <span>{{ $store.state.assetName }}</span>
        <span class="edit" @click="editName">修改</span>
      </div>
      <div class="profile-block" v-loading="uploadLoading">
        <span>logo设置：</span>
        <div class="log-container">
          <div class="old-log">
            <img
              :src="this.$store.state.logoUrl || '../../assets/images/logo.png'"
              alt=""
            />
          </div>
          <div class="upload">
            <el-upload
              class="avatar-uploader"
              :action="
                'https://testfa.jiandeng.tech/api/newAir/uploadPic/' + assetID
              "
              :show-file-list="false"
              :headers="headers"
              :on-success="uploadSuccessHandler"
              :on-error="uploadErrorHandler"
              :before-upload="uploadBeforeHandler"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
      </div>
      <!--      <div class="profile-block">-->
      <!--        <span>手机绑定：</span>-->
      <!--        <span>18888888888</span>-->
      <!--      </div>-->
    </div>
    <width-dialog
      :property="dialogProperty"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      width="260px"
    >
      <el-input
        v-model="newName"
        placeholder="请输入15字以内的名称"
        size="mini"
      ></el-input>
    </width-dialog>
    <width-dialog
      :property="dialogPropertyPhone"
      @cancel="dialogPhoneCancel"
      @confirm="dialogPhoneConfirm"
      width="420px"
    >
      <el-input
        v-model="newPhone"
        placeholder="请输入手机号"
        size="medium"
      ></el-input>
      <div class="phone-code">
        <el-input
          v-model="phoneCode"
          size="medium"
          placeholder="请输入验证码"
        ></el-input>
        <div class="valid-num">
          <span v-if="codeShow" @click="getPhoneCode">获取验证码</span>
          <span v-if="!codeShow">{{ count }}</span>
        </div>
      </div>
    </width-dialog>
  </div>
</template>

<script>
import WidthDialog from "../../components/dialog/WidthDialog";
import { getToken } from "../../utils/token";
export default {
  name: "Profile",
  components: {
    WidthDialog,
  },
  data() {
    return {
      count: 60,
      uploadLoading: false,
      active: 1,
      dialogProperty: {
        show: false,
        title: "修改名称",
        loading: false,
      },
      dialogPropertyPhone: {
        show: false,
        title: "绑定手机",
        loading: false,
      },
      newName: "",
      newPhone: "",
      phoneCode: "",
      codeShow: true,
      time: null,
      setNameLoading: false,
      tableData: [
        {
          id: "JD123123",
          name: "一年级1班",
          time: "2021-05-21 16:33:22",
          content: "初效滤网剩余1小时",
        },
        {
          id: "JD123124",
          name: "一年级2班",
          time: "2021-05-21 16:33:22",
          content: "初效滤网剩余1小时",
        },
        {
          id: "JD123125",
          name: "一年级3班",
          time: "2021-05-21 16:33:22",
          content: "初效滤网剩余1小时",
        },
        {
          id: "JD123126",
          name: "一年级4班",
          time: "2021-05-21 16:33:22",
          content: "初效滤网剩余1小时",
        },
      ],
      headers: {},
    };
  },
  methods: {
    dialogCancel() {
      this.dialogProperty.show = false;
    },
    dialogPhoneCancel() {
      this.dialogPropertyPhone.show = false;
    },
    dialogConfirm() {
      if (!this.newName) {
        this.dialogProperty.show = false;
        return;
      }
      if (this.newName.length > 15) {
        this.$message("请输入15个字以内的名称");
      } else {
        this.dialogProperty.loading = true;
        this.$http
          .setNewAssetName(window.localStorage.getItem("assetID"), this.newName)
          .then(() => {
            this.$store.dispatch("getAssetNameAction");
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.dialogProperty.loading = false;
            this.dialogProperty.show = false;
          })
          .catch(() => {
            this.dialogProperty.loading = false;
            this.dialogProperty.show = false;
          });
      }
    },
    dialogPhoneConfirm() {
      let test = /^1[3-9]\d{9}$/.test(this.newPhone);
      if (!test) {
        this.$message({
          message: "手机号格式不正确",
          type: "error",
        });
        return;
      }
      if (!this.newPhone) {
        this.$message({
          message: "请输入手机验证码",
          type: "error",
        });
        return;
      }
      let params = {
        userId: this.$store.state.userID,
        mobilePhone: this.newPhone,
        code: this.phoneCode,
      };
      this.dialogPropertyPhone.loading = true;
      this.$http
        .changeUserPhone(params)
        .then((res) => {
          this.dialogPropertyPhone.loading = false;
          if (res.data.success) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.msg || "绑定失败",
              type: "error",
            });
          }
          this.dialogPropertyPhone.show = false;
          this.newPhone = "";
          this.phoneCode = "";
          this.$store.dispatch("userLoginProfile");
        })
        .catch(() => {
          this.dialogPropertyPhone.loading = false;
          this.$message({
            message: "修改失败",
            type: "error",
          });
        });
    },
    editName() {
      this.dialogProperty.show = true;
    },
    bindPhone() {
      this.dialogPropertyPhone.show = true;
    },
    uploadSuccessHandler() {
      this.$store.dispatch("getLogoUrl");
      this.uploadLoading = false;
      this.$message({
        type: "success",
        message: "操作成功",
      });
    },
    uploadErrorHandler() {
      this.uploadLoading = false;
      this.$message({
        type: "error",
        message: "上传失败，请稍后再试",
      });
    },
    uploadBeforeHandler() {
      this.uploadLoading = true;
    },
    getPhoneCode() {
      if (this.timer) return;
      let test = /^1[3-9]\d{9}$/.test(this.newPhone);
      if (!test) {
        this.$message({
          message: "手机号格式不正确",
          type: "error",
        });
        return;
      }
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.codeShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.codeShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      this.$http.getAuthCode(this.newPhone).then((res) => {
        if (res.data?.success) {
          this.$message({
            message: "验证码已发送",
            type: "success",
          });
        } else {
          this.$message({
            message: "验证码发送失败",
            type: "error",
          });
        }
      });
    },
  },
  created() {
    this.headers = {
      "X-Authorization": "Bearer " + getToken("token"),
    };
  },
  computed: {
    username() {
      return this.$store.state.username;
    },
    phone() {
      return this.$store.state.phone;
    },
    assetID() {
      return window.localStorage.getItem("assetID");
    },
  },
};
</script>

<style scoped lang="scss">
.profile-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  min-width: 999px;
  .phone-code {
    display: flex;
    margin-top: 40px;
    .valid-num {
      margin-left: 30px;
      width: 130px;
      border: 1px #cccccc solid;
      border-radius: 10px;
      cursor: pointer;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eaeaea;
    }
  }
  .profile-container-header {
    width: 100%;
    display: flex;
    height: 70px;
    padding-left: 40px;
    align-items: center;
    color: #333;
    //span {
    //  width: 90px;
    //  line-height: 50px;
    //  text-align: center;
    //  font-size: 14px;
    //  color: #333;
    //  cursor: pointer;
    //  border-bottom: 1px solid #eaeaea;
    //  &:nth-child(2) {
    //    border-left: 1px solid #eaeaea;
    //    border-right: 1px solid #eaeaea;
    //  }
    //  &:nth-last-child(1) {
    //    flex: 1;
    //  }
    //}
    //.active {
    //  //background-color: #999;
    //  border-bottom: none;
    //  position: relative;
    //  //border-top: 4px solid #01cfa5;
    //  &::before {
    //    content: "";
    //    width: 90px;
    //    height: 4px;
    //    background-color: #01cfa5;
    //    position: absolute;
    //    left: 0;
    //  }
    //}
  }
  .profile-container-content {
    padding-left: 40px;
    //padding-top: 20px;
    display: flex;
    flex-direction: column;
    .profile-block {
      height: 60px;
      border-bottom: 1px solid #eaeaea;
      font-size: 14px;
      color: #333;
      &:nth-last-child(1) {
        border-bottom: none;
        display: flex;
        margin-top: 20px;
      }
      span {
        &:nth-child(2) {
          margin-left: 30px;
          line-height: 60px;
        }
      }
      .edit {
        margin-left: 30px;
        color: #1594fd;
        cursor: pointer;
      }
      .log-container {
        display: flex;
        margin-left: 30px;
        .old-log {
          width: 60px;
          height: 60px;
          border: 1px dashed #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 58px;
            height: 58px;
            //padding: 10px;
            background-color: #01cfa5;
          }
        }
        .upload {
          width: 60px;
          height: 60px;
          border: 1px dashed #d9d9d9;
          margin-left: 15px;
          line-height: 60px;
          .avatar-uploader {
            text-align: center;
          }
          .avatar-uploader-icon {
            &::before {
              font-size: 20px;
            }
          }
        }
      }
    }
    ::v-deep .el-table {
      .el-table__row {
        height: 60px;
      }
      .is-leaf {
        background-color: #f4f4f5;
      }
    }
  }
}
</style>
